<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="isAudioEnabled ? 'white': 'error'"
        :class="isAudioEnabled ? 'primary--text' : 'white--text'"
        fab v-bind="attrs" v-on="on"
        @click="$emit('click')"
      >
        <v-icon v-text="isAudioEnabled ? 'mic_none' : 'mic_off'" />
      </v-btn>
    </template>
    <span v-text="isAudioEnabled ? $t('streaming.buttons.disableAudio') : $t('streaming.buttons.enableAudio')" />
  </v-tooltip>
</template>

<script>
export default {
  name: 'SwitchAudioButton',
  props: {
    isAudioEnabled: { type: Boolean, required: true },
  },
}
</script>
